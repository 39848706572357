import { FC } from 'react'
import {LedGreen, LedRed} from '../../../components/Leds'
import { SensorTypes, TypeSensor } from '../core/_models';
type Props = {
  measuringBox: number;
  sensor: TypeSensor;
}

const MeasuringBox: FC<Props> = ({measuringBox, sensor}) => {
  return (
    <div className="w-130px border-dark border-opacity-50 bg-opacity-10 border p-1 pt-0 rounded">
      <div id='box_id'>
        <span className="fs-7 fw-bold"></span>
        <span className="fs-6 fw-bold">Presidente Wilson</span>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold"> Nº CMB: </span>
        <span className="fs-6 fw-bold">100{measuringBox}</span>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold"> BLUETOOTH: </span>
        <span className="fs-6 fw-bold">2068</span>
      </div>

      <div className="border-bottom border-dark border-opacity-50"></div>

      <div id="leds" className="d-flex justify-content-end gap-3 me-1">
        <div className="animation-blink" style={{height: "17px"}}><LedGreen /></div>
        {(sensor.id === SensorTypes.door.id || sensor.id === SensorTypes.bolt.id || sensor.id === SensorTypes.door_lock.id) && (
          <div className="animation-blink" style={{height: "17px"}}><LedRed /></div>
        )}
        {sensor.id === SensorTypes.door.id && (
          <>
            <div className="animation-blink" style={{height: "17px"}}><LedRed /></div>
            <div className="animation-blink" style={{height: "17px"}}><LedGreen /></div>
          </>
        )}
      </div>

    </div>
  );
}

export {MeasuringBox}