import { FC } from 'react'
import { toast } from 'react-toastify'
import { LedYellow } from '../../../components/Leds'
import { TypeOpeningRequest } from '../core/_models';
import { openDoorRelease, openDoorDenied } from '../core/_requests'

type Props = {
  openingRequest: TypeOpeningRequest;
  refetchOpeningRequests: () => void;
}

const MeasuringBoxOpeningRequests: FC<Props> = ({openingRequest, refetchOpeningRequests}) => {
  const handleOpenDoorRelease = async (box_id: number, cmb_number: number) => {
    const res = await openDoorRelease(box_id)
    if (res?.success) {
      refetchOpeningRequests()
      toast.success(`Envio da aprovação de abertura da caixa ${cmb_number} realizado com sucesso!`)
    } else {
      toast.error(`Erro no envio da aprovação de abertura da caixa ${cmb_number}`)
    }
  }
  
  const handleOpenDoorDenied = async (box_id: number, cmb_number: number) => {
    const res = await openDoorDenied(box_id)
    if (res?.success) {
      refetchOpeningRequests()
      toast.success(`Envio da negação de abertura da caixa ${cmb_number} realizado com sucesso!`)
    } else {
      toast.error(`Erro no envio da negação de abertura da caixa ${cmb_number}`)
    }
  }

  return (
    <div className="border-dark border-opacity-50 bg-opacity-10 border p-1 pt-0 rounded" 
      style={{ width: "160px" }}
      >
      <div id='project_name' title={`Projeto: ${openingRequest.project_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"></span>
        <span className="fs-6 fw-bold">{openingRequest.project_name}</span>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold">Nº CMB: </span>
        <span className="fs-6 fw-bold">{openingRequest.cmb_number}</span>
      </div>
      <div id='bluetooth' title='Número Bluetooth'>
        <span className="fs-7 fw-bold"><i className="bi-bluetooth"></i> </span>
        <span className="fs-6 fw-bold">{openingRequest.bluetooth}</span>
      </div>
      <div id='request_from_user' title={`Usuário Solicitante: ${openingRequest.user_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-person-circle"></i> </span>
        <span className="fs-6 fw-bold">{openingRequest.user_name}</span>
      </div>

      <div className="border-bottom border-dark border-opacity-50"></div>

      <div id="leds" className="d-flex justify-content-between gap-3 me-1" style={{ marginTop: '1px'}}>
        <div>
          <button style={{ marginTop: "1px", border: 0, backgroundColor: "#01a011", paddingTop: 0, paddingBottom: 0, paddingLeft: "4px", paddingRight: "4px", borderRadius: "4px", fontSize: "11px", fontWeight: "bold", marginRight: "8px", color: "white"}}
            onClick={() => handleOpenDoorRelease(openingRequest.measuring_box_id, openingRequest.cmb_number)}>
            Aprovar
          </button>
          <button style={{ marginTop: "1px", border: 0, backgroundColor: "#dd0202", paddingTop: 0, paddingBottom: 0, paddingLeft: "4px", paddingRight: "4px", borderRadius: "4px", fontSize: "11px", fontWeight: "bold", color: "white" }}
            onClick={() => handleOpenDoorDenied(openingRequest.measuring_box_id, openingRequest.cmb_number)}>
            Negar
          </button>
        </div>
        <div className="animation-blink" style={{height: "17px"}}><LedYellow /></div>
      </div>

    </div>
  );
}

export {MeasuringBoxOpeningRequests}