import { FC, useState } from "react"
import { useNavigate } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100vh',
}

type Coordinate = {
  lat: number,
  lng: number,
  cmb_number?: number,
}

const positions: Coordinate[] = [
  {
    cmb_number: 2261,
    lat: -23.00702290670888,
    lng: -43.341672782731685,
  },
  {
    cmb_number: 2262,
    lat: -23.003012538080906,
    lng: -43.34906543026037
  },
  {
    cmb_number: 2263,
    lat: -23.0082761907738,
    lng: -43.3418338316334,
  },
  {
    cmb_number: 2264,
    lat: -23.009994383878162,
    lng: -43.34296849435009,
  },
  {
    cmb_number: 2265,
    lat: -23.009185825142946,
    lng: -43.344557022153445,
  },
  {
    cmb_number: 2266,
    lat: -23.001918830539857,
    lng: -43.340206393752815,
  },
  {
    cmb_number: 2267,
    lat: -23.00901697513983,
    lng: -43.33682895061689,
  },
]

const Map: FC = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || ''
  })

  console.log('Chave: ', process.env.REACT_APP_GOOGLE_MAPS_API)
  console.log('REACT_APP_API_URL: ', process.env.REACT_APP_API_URL)
  console.log('REACT_APP_VERSION: ', process.env.REACT_APP_VERSION)
  console.log('REACT_APP_BASE_LAYOUT_CONFIG_KEY: ', process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY)

  // const [map, setMap] = useState(null)

  const redBoxIcon = '/media/icons/boxes/box-red-32.png'
  const greenBoxIcon = '/media/icons/boxes/box-green-32.png'
  const greyBoxIcon = '/media/icons/boxes/box-grey-32.png'

  // const onMapLoad = (map: google.maps.Map) => {
  //   console.log('map: ', map)
  // }

  // const onUnmount = useCallback(function callback(map: any) {
  //   setMap(null)
  // }, [])

  const [activeMarker, setActiveMarker] = useState<Coordinate>();

  const handleMarkerClick = (position: Coordinate) => {
    setActiveMarker({...position, lat: position.lat + 0.00069});
  };

  const navigate = useNavigate()

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={positions[0]}
        zoom={16}
        // onLoad={onMapLoad}
        // onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
          <Marker position={positions[0]} icon={greenBoxIcon} onClick={() => handleMarkerClick(positions[0])} />
          <Marker position={positions[1]} icon={greyBoxIcon} onClick={() => handleMarkerClick(positions[1])} />
          <Marker position={positions[2]} icon={greenBoxIcon} onClick={() => handleMarkerClick(positions[2])} />
          <Marker position={positions[3]} icon={redBoxIcon} onClick={() => handleMarkerClick(positions[3])} />
          <Marker position={positions[4]} icon={greyBoxIcon} onClick={() => handleMarkerClick(positions[4])} />
          <Marker position={positions[5]} icon={greenBoxIcon} onClick={() => handleMarkerClick(positions[5])} />
          <Marker position={positions[6]} icon={redBoxIcon} onClick={() => handleMarkerClick(positions[6])} />
        </>
        {activeMarker && (
          <InfoWindow
            position={activeMarker}
            onCloseClick={() => setActiveMarker(undefined)}
          >
            <div className="me-4 mb-4 ms-1 p-0 mt-0">
              <h2>Informações da caixa {activeMarker.cmb_number}</h2>
              <p>Posição no mapa Lat. {activeMarker.lat}, Lng. {activeMarker.lng}</p>
              <button className='btn btn-primary p-2' onClick={() => {
                console.log('Boston Maratona')
                navigate('/apps/measuring-box-management/measuring-box')
              }}>
                Editar Caixa
              </button>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
  ) : <></>
}

export {Map}