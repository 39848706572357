import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {initialMeasuringBox, MeasuringBox, MeasuringBoxType, Project} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {MeasuringBoxesListLoading} from '../components/loading/MeasuringBoxesListLoading'
import {createMeasuringBoxes, updateMeasuringBoxes, getAllProjects} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useAuth} from '../../../../auth'

type Props = {
  isMeasuringBoxLoading: boolean
  response: any
}

const editMeasuringBoxSchema = Yup.object().shape({
  bluetooth: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(1, 'Mínimo 1 dígito')
    .max(50, 'Máximo 50 dígitos')
    .required('Bluetooth é obrigatório'),
  address: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Máximo 100 caracteres')
    .required('Nome do address é obrigatório'),
  cmb_number: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(1, 'Mínimo 1 dígito')
    .max(50, 'Máximo 50 dígitos')
    .required('Número CMB é obrigatório'),
  default_password: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(4, 'Mínimo 4 dígitos')
    .max(50, 'Máximo 50 dígitos')
    .required('Senha Padrão é obrigatória'),
  project_id: Yup.number()
    .moreThan(0,'Selecione um projeto')
    .required('Selecione um projeto'),
  status_id: Yup.number()
    .moreThan(0,'Selecione um status')
    .required('Selecione um status')
})

const EditModalForm: FC<Props> = ({response, isMeasuringBoxLoading}) => {
  const measuringBox = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  const [measuringBoxForEdit] = useState<MeasuringBox>({
    ...measuringBox,
    name: measuringBox.name || initialMeasuringBox.name,
    company_id: measuringBox.company_id || initialMeasuringBox.company_id,
    bluetooth: measuringBox.bluetooth || initialMeasuringBox.bluetooth,
    address: measuringBox.address || initialMeasuringBox.address,
    cmb_number: measuringBox.cmb_number || initialMeasuringBox.cmb_number,
    default_password: measuringBox.default_password || initialMeasuringBox.default_password,
    status_id: measuringBox.status_id || initialMeasuringBox.status_id,
    project_id: measuringBox.project_id || initialMeasuringBox.project_id,
    type: measuringBox.type || initialMeasuringBox.type,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: measuringBoxForEdit,
    validationSchema: editMeasuringBoxSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMeasuringBoxes(values)
          toast.success(`Caixa ${values.bluetooth} atualizada com sucesso`)
        } else {
          const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
          values.company_id = companyId;
          await createMeasuringBoxes(values)
          toast.success(`Caixa ${values.bluetooth} criada com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const {data: projects} = useQuery(
    `${QUERIES.PROJECTS}`,
    () => {
      const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
      return getAllProjects(companyId);
    },
    {
      enabled: !isMeasuringBoxLoading,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row mb-6'>
            <div className='col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>Bluetooth</label>
              <input
                placeholder='Bluetooth'
                {...formik.getFieldProps('bluetooth')}
                type='text'
                name='bluetooth'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.bluetooth && formik.errors.bluetooth},
                  {
                    'is-valid': formik.touched.bluetooth && !formik.errors.bluetooth,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              />
              {formik.touched.bluetooth && formik.errors.bluetooth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bluetooth}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-6'>
              <label className='required fw-bold fs-6 mb-2'>Tipo de Caixa</label>
              <div className="mt-2 d-flex gap-6">
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.online)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.online}
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Online
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.offline)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.offline}
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Offline
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Número CMB</label>
            <input
              placeholder='Número CMB'
              {...formik.getFieldProps('cmb_number')}
              type='text'
              name='cmb_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.cmb_number && formik.errors.cmb_number},
                {
                  'is-valid': formik.touched.cmb_number && !formik.errors.cmb_number,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeasuringBoxLoading}
            />
            {formik.touched.cmb_number && formik.errors.cmb_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cmb_number}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Projeto</label>
            <select
              className='form-control form-control-solid form-select mb-3 mb-lg-0'
              {...formik.getFieldProps('project_id')}
              name='project_id'
              disabled={formik.isSubmitting || isMeasuringBoxLoading}
            >
              <option selected  value="0">
                Selecione o projeto
              </option>
              {projects &&
                (projects as Project[]).map((project) => {
                  return <option value={`${project.id}`}>{project.name}</option>
                })}
            </select>
            {formik.touched.project_id && formik.errors.project_id && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.project_id}</span>
              </div>
            )}
          </div>
          
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Endereço</label>
            <input
              placeholder='Endereço'
              {...formik.getFieldProps('address')}
              type='text'
              name='address'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.address && formik.errors.address},
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeasuringBoxLoading}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Senha Padrão</label>
            <input
              placeholder='Senha Padrão'
              {...formik.getFieldProps('default_password')}
              type='text'
              name='default_password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.default_password && formik.errors.default_password},
                {
                  'is-valid': formik.touched.default_password && !formik.errors.default_password,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeasuringBoxLoading}
            />
            {formik.touched.default_password && formik.errors.default_password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.default_password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              >
                <option selected value="0">
                  Selecione o status
                </option>
                <option value={'6'}>Ativo</option>
                <option value={'7'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isMeasuringBoxLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isMeasuringBoxLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isMeasuringBoxLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isMeasuringBoxLoading) && <MeasuringBoxesListLoading />}
    </>
  )
}

export {EditModalForm}
