import { Response } from "../../../../_metronic/helpers";

export type TypeSensor = {
  id: number;
  icon: string;
  description: string;
}

export type TypeSensors = {
  door: TypeSensor;
  bolt: TypeSensor;
  door_lock: TypeSensor;
  vibration: TypeSensor;
  energy: TypeSensor;
  violation: TypeSensor;
  sound: TypeSensor;
  maximum_time_for_open_door: TypeSensor;
  request_opening_of_safe: TypeSensor;
  manual_opening_by_bluetooth: TypeSensor;
  cable_cut_alarm: TypeSensor;
}

export const SensorTypes: TypeSensors = {
  request_opening_of_safe: {id: 9, icon: 'bi-unlock', description: 'Solicitação de abertura'},
  door: {id: 1, icon: 'bi-door-closed', description: 'Portas'},
  bolt: {id: 2, icon: 'bi-file-lock2', description: 'Ferrolhos'},
  door_lock: {id: 3, icon: 'bi-key', description: 'Fechaduras'},
  vibration: {id: 4, icon: 'bi-phone-vibrate', description: 'Vibração'},
  energy: {id: 5, icon: 'bi-plug', description: 'Energia'},
  violation: {id: 6, icon: 'bi-box-arrow-right', description: 'Violação'},
  sound: {id: 7, icon: 'bi-volume-up', description: 'Som'},
  maximum_time_for_open_door: {id: 8, icon: 'bi-door-open', description: 'Tempo máx. abertura de porta'},
  manual_opening_by_bluetooth: {id: 10, icon: 'bi-bluetooth', description: 'Abertura manual por bluetooth'},
  cable_cut_alarm: {id: 11, icon: 'bi-scissors', description: 'Alarme de corte de cabo'},
}

export type TypeOpeningRequest = {
  description: string,
  measuring_box_id: number,
  address: string,
  bluetooth: number,
  cmb_number: number,
  default_password: string,
  company_name: string,
  project_id: number,
  project_name: string,
  user_id: number,
  user_name: string,
}

export type OpeningRequestQueryResponse = Response<Array<TypeOpeningRequest>>

export type TypeResponseSuccess = {
  success: boolean
}