import { FC, useState } from "react"
import { MeasuringBox } from "./MeasuringBox"
import { SensorInfo } from "./SensorInfo"
import { OpeningRequestQueryResponse, SensorTypes, TypeOpeningRequest, TypeSensor } from "../core/_models"
import clsx from "clsx"
import "../sensors.css"
import { MeasuringBoxOpeningRequests } from "./MeasuringBoxOpeningRequests"
import { useQuery } from "react-query"
import { getOpeningRequestsFilter } from "../core/_requests"
import { useAuth } from "../../../modules/auth"

const Sensors: FC = () => {
  const {currentUser} = useAuth()
  const [selectedSensor, setSelectedSensor] = useState<TypeSensor>()
  const [boxes, setBoxes] = useState<Array<number>>([])

  const {
    isFetching: isFetchingOpeningRequests,
    refetch: refetchOpeningRequests,
    data: openingRequests,
  } = useQuery(
    'OPENING_REQUESTS_QUERY',
    async () => {
      const response: OpeningRequestQueryResponse = await getOpeningRequestsFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
          start_date: '2024-05-20',
          end_date: '2024-05-27'
        }
      )
      const openingRequests = response?.data as TypeOpeningRequest[]

      return openingRequests
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <>
    <div id="sensors_page" className="d-flex flex-row">

      <div
        id="side_menu"
        className="d-flex flex-column custom-height overflow-scroll w-300px bg-secondary pt-3 pb-3 ps-3 pe-3 rounded"
        >

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.request_opening_of_safe.id})}
          onClick={() => {
            setSelectedSensor(SensorTypes.request_opening_of_safe)
          }}>
          <SensorInfo sensor={SensorTypes.request_opening_of_safe} amount={openingRequests?.length || 0} isFetching={isFetchingOpeningRequests} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12])
            setSelectedSensor(SensorTypes.door)
          }}>
          <SensorInfo sensor={SensorTypes.door} amount={12} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.bolt.id})}
          onClick={() => {
            setBoxes([50,51,52,53,54,55])
            setSelectedSensor(SensorTypes.bolt)
          }}>
          <SensorInfo sensor={SensorTypes.bolt} amount={6} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door_lock.id})}
          onClick={() => {
            setBoxes([60,61,62,63])
            setSelectedSensor(SensorTypes.door_lock)
          }}>
          <SensorInfo sensor={SensorTypes.door_lock} amount={4} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.vibration.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37])
            setSelectedSensor(SensorTypes.vibration)
          }}>
          <SensorInfo sensor={SensorTypes.vibration} amount={37} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.energy.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20])
            setSelectedSensor(SensorTypes.energy)
          }
        }>
          <SensorInfo sensor={SensorTypes.energy} amount={20} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.violation.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,
              41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,
              78,79,80,81,82,83]
            )
            setSelectedSensor(SensorTypes.violation)
          }}>
          <SensorInfo sensor={SensorTypes.violation} amount={83} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.sound.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,
              41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,
              78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,
              111,112,113,114])
            setSelectedSensor(SensorTypes.sound)
          }}>
          <SensorInfo sensor={SensorTypes.sound} amount={114} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.maximum_time_for_open_door.id})}
          onClick={() => {
            setBoxes([1,2,3])
            setSelectedSensor(SensorTypes.maximum_time_for_open_door)
          }}>
          <SensorInfo sensor={SensorTypes.maximum_time_for_open_door} amount={3} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.manual_opening_by_bluetooth.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17])
            setSelectedSensor(SensorTypes.manual_opening_by_bluetooth)
          }}>
          <SensorInfo sensor={SensorTypes.manual_opening_by_bluetooth} amount={17} isFetching={false} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.cable_cut_alarm.id})}
          onClick={() => {
            setBoxes([1,2,3,4,5,6,7,8,9,10,11])
            setSelectedSensor(SensorTypes.cable_cut_alarm)
          }}>
          <SensorInfo sensor={SensorTypes.cable_cut_alarm} amount={11} isFetching={false} />
        </button>
      </div>

      <div id="boxes_area" className="d-flex flex-column w-100 custom-height">
        <div id="header" className="d-flex flex-row ms-1 mb-2 bg-secondary rounded ps-3 pe-3">
          <div className="d-flex flex-column justify-content-center col-sm-10 fs-4 fw-bold">
            <div>
              <i className={selectedSensor?.icon}> </i>{selectedSensor?.description}
            </div>
            {selectedSensor?.id === SensorTypes.request_opening_of_safe.id &&
              <div>
                {!selectedSensor
                ? <><i className="bi-arrow-bar-left"></i> Selecione um tipo sensor da lista para visualizar as caixas</>
                : openingRequests && openingRequests.length > 1
                ? `${openingRequests.length} caixas sendo visualizadas`
                : `${openingRequests && openingRequests.length} caixa sendo visualizada`
                }
              </div>
            }
            {selectedSensor?.id !== SensorTypes.request_opening_of_safe.id &&
              <div>
                {!selectedSensor
                ? <><i className="bi-arrow-bar-left"></i> Selecione um tipo sensor da lista para visualizar as caixas</>
                : boxes.length > 1
                ? `${boxes.length} caixas sendo visualizadas`
                : `${boxes.length} caixa sendo visualizada`
                }
              </div>
            }
          </div>
          <div className="col-sm-2 pt-2 pb-2">
            <input
                className="form-control"
                placeholder='Pesquisar Caixa'
                type='text'
                name='search_measuring_box'
            />
          </div>
        </div>

        <div id="boxes" className="h-100 bg-secondary rounded ms-1 overflow-scroll">
          <div className="d-flex flex-wrap p-2">
            {selectedSensor && selectedSensor.id === SensorTypes.request_opening_of_safe.id && openingRequests && openingRequests.map((box) => {
              return (
                <div className="me-2 mb-2">
                  {<MeasuringBoxOpeningRequests openingRequest={box} refetchOpeningRequests={refetchOpeningRequests}/>
                  }
                </div>
              )
            })}
            {selectedSensor && selectedSensor.id !== SensorTypes.request_opening_of_safe.id && boxes.map((x) => {
              return (
                <div className="me-2 mb-2">
                  {selectedSensor && <MeasuringBox measuringBox={x} sensor={selectedSensor} />}
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export {Sensors}