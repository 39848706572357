import axios, {AxiosResponse} from 'axios'
import {
  OpeningRequestQueryResponse,
  TypeResponseSuccess,
} from './_models'
import { ID, Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

const getOpeningRequestsFilter = (query: Record<string, any>): Promise<OpeningRequestQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/opening-requests/filter`, {params: query})
    .then((d: AxiosResponse<OpeningRequestQueryResponse>) => d.data)
}

const openDoorRelease = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios({
    method: 'post',
    url: `${API_URL}/log-box/nastek/open-door-released/${measuring_box_id}`,
    headers: {
      secret_key: 'X5VRrF&6(@!45dFN{xZ'
    }
  })
  .then((response: Response<TypeResponseSuccess>) => response.data)
}

const openDoorDenied = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/open-door-denied/${measuring_box_id}`, {}, {headers: {secret_key: 'X5VRrF&6(@!45dFN{xZ'}})
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

export {
  getOpeningRequestsFilter,
  openDoorRelease,
  openDoorDenied,
}
